
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import type { Comment } from "../store";

@Component({
  filters: {
    created_at_from_now(value, appLanguage): string {
      if (!value) return "";

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class CommentComponent extends Vue {
  @Prop(Object) readonly comment!: Comment;

  bottomSheet = false;
  deleteCommentDialog = false;

  tiles = [
    {
      title: this.$t("WHITEBOARD.COMMENTS.EDIT_COMMENT"),
      icon: "mdi-comment-edit-outline",
      callback: () => this.updateComment()
    },
    {
      title: this.$t("WHITEBOARD.COMMENTS.DELETE_COMMENT"),
      icon: "mdi-comment-remove-outline",
      callback: () => this.showDialog()
    }
  ];

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get matchQuery() {
    return +this.$route.query.comment_id == this.comment.id;
  }

  showBottomSheet() {
    this.bottomSheet = true;
  }

  showDialog() {
    this.deleteCommentDialog = true;
    this.bottomSheet = false;
  }

  updateComment() {
    this.$emit("update-comment", this.comment);
    this.bottomSheet = false;
  }

  deleteComment() {
    this.$emit("delete-comment", this.comment);
    this.deleteCommentDialog = false;
    this.bottomSheet = false;
  }
}
