
import { Component, Prop, Vue } from "vue-property-decorator";
import Screen from "@/app/modules/_global/classes/Screen";

import PostComponent from "../components/Post.vue";
import CommentComponent from "../components/Comment.vue";
import CommentFormComponent from "../components/CommentForm.vue";

import type { Comment, Post } from "../store";

@Component({
  components: {
    PostComponent,
    CommentComponent,
    CommentFormComponent
  }
})
export default class PostPage extends Vue {
  @Prop(Number) readonly postId!: number;

  boundedContentHeight = 0;

  post: null | Post = null;
  commentId: null | number = null;
  comments: Comment[] = [];
  message = "";

  created() {
    this.$nextTick(() => {
      const screen = new Screen();
      this.boundedContentHeight = screen.getBoundedContentHeight() - 138;
    });

    this.fetchPostById();
    this.fetchCommentsPostById();
  }

  get postName() {
    return this.post?.translation?.name;
  }

  fetchPostById() {
    this.$store
      .dispatch("posts/fetchById", this.postId)
      .then(({ data }) => (this.post = data.data));
  }

  fetchCommentsPostById() {
    this.$store.dispatch("posts/fetchCommentsByPostId", this.postId).then(({ data }) => {
      this.comments = data.data;

      if (this.$route.query.comment_id) {
        this.scrollToComment(+this.$route.query.comment_id);
      }
    });
  }

  updateComment(comment: Comment) {
    this.commentId = comment.id;
    this.message = comment.message;
  }

  postComment(comment: Comment) {
    this.$router.replace({ query: { comment_id: `${comment.id}` } }).catch(() => null);

    this.scrollToComment(comment.id);
    this.comments.unshift(comment);
  }

  patchComment(comment: Comment) {
    this.$router.replace({ query: { comment_id: `${comment.id}` } }).catch(() => null);

    this.scrollToComment(comment.id);
    const index = this.comments.findIndex(_ => comment.id == _.id);
    this.comments[index] = comment;
    this.commentId = null;
  }

  deleteComment(comment: Comment) {
    this.$store.dispatch("posts/deleteComment", comment.id).then(() => {
      this.$router.replace({ query: {} }).catch(() => null);

      this.scrollToComment(comment.id);
      const index = this.comments.findIndex(_ => comment.id == _.id);
      this.comments.splice(index, 1);
      this.commentId = null;
    });
  }

  scrollToComment(id: number) {
    this.$nextTick(() => {
      this.$vuetify.goTo(`#comment-${id}`, { offset: 20 });
    });
  }
}
