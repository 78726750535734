
import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class CommentFormComponent extends Vue {
  @Prop(Number) readonly postId!: number;
  @Prop(Number) readonly commentId?: number;

  @Model("input", { type: String }) readonly message!: string;

  @Watch("commentId")
  onChanged(value) {
    if (value) {
      this.$nextTick(() => {
        (this.$refs.message as HTMLInputElement)?.focus();
      });
    } else {
      this.$emit("input", "");
    }
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  submitForm() {
    !this.commentId ? this.postComment() : this.updateComment();
  }

  postComment() {
    const formData = {
      post_id: this.postId,
      message: this.message
    };

    this.$store.dispatch("posts/postComment", formData).then(({ data }) => {
      this.$emit("input", "");
      this.$emit("post-comment", data.data);
    });
  }

  updateComment() {
    const formData = {
      id: this.commentId,
      body: {
        message: this.message
      }
    };

    this.$store.dispatch("posts/patchComment", formData).then(({ data }) => {
      this.$emit("input", "");
      this.$emit("patch-comment", data.data);
    });
  }
}
