
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import type { Post } from "../store";

@Component({
  filters: {
    created_at_from_now(value, appLanguage): string {
      if (!value) return "";

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class PostComponent extends Vue {
  @Prop(Object) readonly post!: Post;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get urlAvatar() {
    return this.post.author?.url_avatar;
  }

  get urlUpload() {
    return this.post?.url_upload;
  }

  get postName() {
    return this.post?.translation?.name;
  }

  get postDescription() {
    return this.post?.translation?.description;
  }
}
